<script lang="ts">
  import type { ChartData, ChartOptions } from 'chart.js';
  import Doughnut from 'svelte-chartjs/src/Doughnut.svelte';
  import { green, yellow, orange, red, purple, cyan, blue, gray } from 'design/src/tokens/colors';
  import { sum } from 'shared/src/utils/sum';
  import CountTo from '../CountTo.svelte';
  import { twMerge } from 'tailwind-merge';

  const defaultBackgroundColors = [
    green[400],
    yellow[500],
    orange[600],
    red[200],
    purple[400],
    blue[300],
    cyan[500],
  ];

  const defaultHoverBackgroundColors = [
    green[300],
    yellow[400],
    orange[500],
    red[100],
    purple[300],
    blue[200],
    cyan[400],
  ];

  const unfilledBackgroundColor = gray[100];
  const unfilledHoverBackgroundColor = gray[50];

  let clazz: string = '';
  export { clazz as class };

  /**
   * Max total is 100 by default, but can be changed by setting `outOf`.
   * Anything beyond total will be filled with gray.
   */
  export let data: number[] = [];
  /**
   * The total possible for the doughnut.
   */
  export let outOf: number = 100;
  /**
   * Labels for each of the values (optional).
   */
  export let labels: string[] = [];
  /**
   * Whether to print the labels separately below the graph
   */
  export let showLabels = false;
  /**
   * The background color to show for each value.
   */
  export let backgroundColors: string[] = defaultBackgroundColors;
  /**
   * The background color to show when a portion of donut is hovered.
   */
  export let hoverBackgroundColors: string[] = defaultHoverBackgroundColors;
  /**
   * The number to display in the middle of the circle
   */
  export let total: number | undefined = 0;
  /**
   * The label for the number in the middle of the circle
   */
  export let totalLabel: string = '';

  // bounce at slightly different rates!
  const bounceVariation = 0.3;
  const bounceCoefficient = 1 + (bounceVariation * Math.random() - bounceVariation / 2);

  // Repeat the bg colors as many times as needed, end with for unfilled.
  $: bgColorsWithUnfilled = backgroundColors && getBGColors();
  $: bgHoverColorsWithUnfilled = backgroundColors && getBGColors(true);

  $: grayData = Math.max(0, outOf - data.reduce(sum));

  let chartData: ChartData<'doughnut'>;
  $: chartData = {
    labels,
    datasets: [
      {
        data: [...data, grayData],
        backgroundColor: bgColorsWithUnfilled,
        hoverBackgroundColor: bgHoverColorsWithUnfilled,
      },
    ],
  };

  let options: ChartOptions<'doughnut'>;
  $: options = {
    responsive: true,
    cutout: '68%',
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
      },
    },
    animation: {
      animateRotate: true,
      easing: 'easeOutBounce',
      duration: 1000 * bounceCoefficient,
    },
  };

  function getBGColors(hover: boolean = false) {
    const colors: string[] = [];
    for (let i = 0; i < data.length || 0; i++) {
      colors.push(getColor(i, hover));
    }
    colors.push(hover ? unfilledHoverBackgroundColor : unfilledBackgroundColor);
    return colors;
  }

  function getColor(i: number, hover: boolean = false) {
    return hover
      ? hoverBackgroundColors[i % hoverBackgroundColors.length]!
      : backgroundColors[i % backgroundColors.length]!;
  }
</script>

<div class={twMerge('w-44', clazz)}>
  <div class="relative">
    <Doughnut data={chartData} {options} aria-hidden />
    <svg class="absolute top-0 left-0 w-full pointer-events-none" viewBox="0 0 300 300">
      <text
        class="fill-orange-600 font-bold"
        style="font-size: 80px;"
        alignment-baseline="baseline"
        text-anchor="middle"
        x="150"
        y="150"
      >
        <CountTo value={total} /><tspan font-size="40">%</tspan></text
      >
      {#if totalLabel}
        <text
          class="fill-gray-light"
          style="font-size: 25px;"
          alignment-baseline="hanging"
          text-anchor="middle"
          x="150"
          y="170"
        >
          {totalLabel}</text
        >
      {/if}
    </svg>
    {#if showLabels && labels && labels.length}
      <div aria-hidden class="mt-4">
        <ul class="text-center">
          {#each labels as label, i}
            <li class="mb-1">
              <span
                class="inline-block w-6 h-4 translate-y-0.5 mr-2"
                style="background-color: {backgroundColors[i % backgroundColors.length]}"
              />{label}
            </li>
          {/each}
        </ul>
      </div>
    {/if}
    <div class="sr-only">
      <p>Pie chart with {data.length} slices, totaling {data.reduce(sum)} out of {outOf}.</p>
      <ul>
        {#each data as datum, i}
          <li>{labels[i] || `Segment ${i + 1}`}: {datum}</li>
        {/each}
      </ul>
    </div>
  </div>
</div>
